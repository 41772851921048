<template>
  <div class="page-title">
    <div class="page-title--left">
      <h1>
        Showing all results for
        <strong class="text-capitalize"> {{ getCurrentSearch() }}</strong>
      </h1>
      <p v-if="searchList.constructor == Array && searchList.length > 1">
        Also search for:
        <span v-for="(search, index) in searchList" :key="`${search}-${index}`">
          <router-link
            v-if="index != selectedIndex"
            class="no-underline text-primaryOnBody text-capitalize"
            :to="{
              name: 'Search',
              query: { q: searchVal, si: String(index) },
            }"
          >
            {{ search
            }}{{ filterdList[filterdList.length - 1] == search ? '' : ',' }}
          </router-link>
        </span>
      </p>
    </div>
    <div class="page-title--right q-ml-auto" v-if="!isMobile">
      <div class="sorting flex items-center">
        <span>Sort :</span>
        <q-select
          dense
          borderless
          v-model="sortBy"
          :options="sortByOptions"
          @input="sortByModified"
          transition-show="jump-up"
          transition-hide="jump-up"
          emit-value
          map-options
        />
      </div>
      <ProductLayoutIcons
        v-if="!isMobile"
        @changeView="(val) => this.$emit('changeView', val)"
        :isListView="searchFilters.isListView"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductLayoutIcons from 'components/common/ProductLayoutIcons'

export default {
  name: 'SearchHeader',
  props: {
    sortVal: {
      type: String,
      default: 'Featured',
    },
    searchVal: {
      type: [Array, String],
      default: '',
    },
    selectedIndex: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ProductLayoutIcons,
  },
  data() {
    return {
      sortBy: this.sortVal,
      searchList: this.searchVal,
    }
  },
  computed: {
    filterdList() {
      if (this.searchList && typeof this.searchList === 'object')
        return this.searchList.filter((value, index) => {
          return index == this.selectedIndex ? false : true
        })
      return 0
    },
    ...mapGetters('product', ['sortByOptions']),
    ...mapGetters('persistedLocal', ['getFilterByPage']),
    searchFilters() {
      return this.getFilterByPage('search')
    },
  },
  methods: {
    sortByModified(value) {
      this.$emit('sorby-modified', this.sortBy)
    },
    getCurrentSearch() {
      if (
        this.searchVal.constructor === Array &&
        this.searchVal.constructor.length
      ) {
        if (typeof this.searchVal[parseInt(this.selectedIndex)] != 'undefined')
          return this.searchVal[parseInt(this.selectedIndex)]
        return this.searchVal[0]
      }
      return this.searchVal
    },
  },
}
</script>
