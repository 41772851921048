<template>
  <q-page class="productListing searchPage">
    <template v-if="query">
      <SearchHeader
        :searchVal="query"
        :sortVal="searchFilters.sortBy"
        @sorby-modified="sortByModified"
        :selectedIndex="getCurrentSearchIndex()"
        @changeView="changeView"
      />
      <Listing
        :searchKeyword="getCurrentSearch()"
        productsBy="SK"
        :sortBy="searchFilters.sortBy"
        :pageSize="searchFilters.pageSize"
        :isListView="!isMobile ? searchFilters.isListView : false"
        ref="searchProductListing"
        @sorby-modified="sortByModified"
      />
    </template>
    <EmptyComponent
      v-else
      image="images/empty-cart.png"
      btnText="Go To Home"
      to="/"
      class="q-mt-xl"
    >
      <p>It looks like you are lost..</p>
    </EmptyComponent>
  </q-page>
</template>

<script>
import SearchHeader from 'components/search/SearchHeader'
import Listing from 'components/product/Listing'
import { mapGetters } from 'vuex'
import {
  setAVSessionID,
  removeAVSessionID,
  tempCacheStorage,
} from 'src/boot/axios'

export default {
  name: 'PageCategry',
  props: {
    query: {
      type: [Array, String],
      default: '',
    },
    selectedIndex: {
      type: String,
      default: '0',
    },
  },
  components: {
    SearchHeader,
    Listing,
  },
  meta() {
    return {
      title: 'Search Result',
      ...this.commonMeta,
    }
  },
  data() {
    return {
      collectionId: '71f0a483-80b7-4590-9359-312c8a291063',
      sortBy: 'Price Low to High',
      isListView: false,
    }
  },
  created() {
    this.$root.$on('eaivVerificationSuccess', this.onEaivSuccess)
    this.$root.$on('eaivVerificationFailed', this.onEaivFail)
  },
  computed: {
    ...mapGetters('product', ['pagination']),
    ...mapGetters('persisted', [
      'eaivPersistedSessionId',
      'eaivPersistedVerificationSuccess',
    ]),
    ...mapGetters('persistedLocal', ['getFilterByPage']),
    ...mapGetters('ageVerification', [
      'isVerificationFail',
      'showVeirificationPopup',
    ]),
    ...mapGetters('customer', ['customer']),
    eaivSessionId() {
      return this.$route.query?.sessionId
    },
    searchFilters() {
      return {
        ...this.getFilterByPage('search'),
        pageSize: 20,
      }
    },
  },
  methods: {
    changeView(isListView) {
      this.$store.commit('persistedLocal/SET_FILTER_BY_PAGE', {
        page: 'search',
        filter: {
          ...this.searchFilters,
          isListView,
        },
      })
    },
    sortByModified(sortBy) {
      this.$store.commit('persistedLocal/SET_FILTER_BY_PAGE', {
        page: 'search',
        filter: {
          ...this.searchFilters,
          sortBy,
        },
      })
    },
    getCurrentSearchIndex() {
      if (
        this.query.constructor === Array &&
        typeof this.query[parseInt(this.selectedIndex)] != 'undefined'
      )
        return parseInt(this.selectedIndex)
      return 0
    },
    getCurrentSearch() {
      if (this.query.constructor === Array) {
        if (typeof this.query[parseInt(this.selectedIndex)] != 'undefined')
          return this.query[parseInt(this.selectedIndex)]
        return this.query[0]
      }
      return this.query
    },
    removeQueryParams(eaivSessionId, isSuccess) {
      if (isSuccess) setAVSessionID(eaivSessionId)
      else {
        this.$store.commit('persisted/SET_EAIV_SESSION_ID', null)
        removeAVSessionID()
      }

      this.$store.commit('ageVerification/SET_VERIFICATION_STATUS', isSuccess)
      // remove sessionId from URL
      let query = Object.assign({}, this.$route.query)
      let params = Object.assign({}, this.$route.params)
      delete query.sessionId
      this.$router.replace({ query, params })
    },
    onEaivSuccess({ eaivSessionId, routeName, isPopup = false }) {
      if (routeName == 'Search') {
        if (!eaivSessionId || !routeName) return
        if (!isPopup) this.removeQueryParams(eaivSessionId, true)

        setTimeout(() => {
          this.$store.commit('ageVerification/SET_LOADING', false)
          this.showSuccess('Age Verified Successfully.')
        }, 500)

        if (isPopup) tempCacheStorage.clear()
        if (this.$refs.searchProductListing)
          this.$refs.searchProductListing.refreshListing()
      }
    },
    onEaivFail({ eaivSessionId, routeName, isPopup = false }) {
      if (routeName == 'Search') {
        if (!eaivSessionId || !routeName) return
        if (!isPopup) this.removeQueryParams(eaivSessionId, false)

        setTimeout(() => {
          this.$store.commit('ageVerification/SET_LOADING', false)
          this.changeDialogState({
            dialog: 'VerificationFailDialog',
            val: true,
            properties: {},
          })
        }, 500)

        if (isPopup) tempCacheStorage.clear()
        if (this.$refs.searchProductListing)
          this.$refs.searchProductListing.refreshListing()
      }
    },
  },
  destroyed() {
    this.$store.commit('product/RESET_PAGINATION')
  },
}
</script>

<style lang="scss">
@media (max-width: 1023px) {
  .productListing.searchPage {
    .page-title {
      display: block;
      padding: 15px 0;
      p {
        padding: 0;
      }
    }
  }
}

.productListing {
  &.searchPage {
    .page-title {
      h1 {
        font-size: 20px;
        color: #353e4a !important;
        strong {
          font-size: 25px;
          color: $primary;
          color: var(--q-color-primary) !important;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
